(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("mobxReact"), require("mobxUtils"), require("sbJsExtends"), require("ReactContainerQuery"), require("ReactDOM"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbDataLayer"), require("sbLocalizationLib"), require("gsap"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-event-list-presenter", ["React", "mobx", "mobxReact", "mobxUtils", "sbJsExtends", "ReactContainerQuery", "ReactDOM", "sbInternalMsgBus", "sbRespBlockLib", "sbDataLayer", "sbLocalizationLib", "gsap"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-event-list-presenter"] = factory(require("React"), require("mobx"), require("mobxReact"), require("mobxUtils"), require("sbJsExtends"), require("ReactContainerQuery"), require("ReactDOM"), require("sbInternalMsgBus"), require("sbRespBlockLib"), require("sbDataLayer"), require("sbLocalizationLib"), require("gsap"));
	else
		root["sb-responsive-event-list-presenter"] = factory(root["React"], root["mobx"], root["mobxReact"], root["mobxUtils"], root["sbJsExtends"], root["ReactContainerQuery"], root["ReactDOM"], root["sbInternalMsgBus"], root["sbRespBlockLib"], root["sbDataLayer"], root["sbLocalizationLib"], root["gsap"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__89__) {
return 